import cai from './requestModules/cai'
import store from '../store'

let indexData = {
  id: null,
  parentId: -1,
  weight: '1',
  name: '首页',
  path: '/index',
  icon: 'index.png',
  permission: null,
  type: '0',
}

// 遍历出目前已有的权限标记
export const permissionOpera = (data) => {
  let arr = [];
  if (Array.isArray(data) && data.length != 0) {
    data.forEach(item => {
      arr.push(item.permission)
      if (item.hasOwnProperty('children') && item.children.length != 0) {
        arr = arr.concat(permissionOpera(item.children));
      }
    })
  }
  return arr;
}

// 角色权限设置后，重新获取本用户的权限菜单
export const refreshAuthority = () => {
  cai.getMenus({}, res => {
    if (res.code == 0) {
      let data = res.data || [];
      data.unshift(indexData);
      store.commit('getMenu', data)
      sessionStorage.setItem('asideMenuData', JSON.stringify(data));
      let hasPerData = permissionOpera(data);
      hasPerData = Array.from(new Set(hasPerData.filter(item => item)));
      sessionStorage.setItem('hasPerData', JSON.stringify(hasPerData));
    }
  })
}