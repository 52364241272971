<template>
  <div class="authorityManagement">
    <div class="allSelect">
      <el-checkbox v-model="checked" @change="allChange(menuTree)">全选</el-checkbox>
    </div>

    <div class="perTree">
      <div class="treeOne" v-for="(item, index) in menuTree" :key="index">
        <div class="treeOne_name pub_tree">
          <el-checkbox @change="changeEvent({item, index}, {selfItem: item, selfIndex: index}, true)" v-model="item.isCheck">{{item.name}}</el-checkbox>
        </div>
        <div class="treeTwo" v-for="(ite, ind) in item.children" :key="ind">
          <div class="treeTwo_name pub_tree">
            <el-checkbox @change="changeEvent({item, index}, {selfItem: ite, selfIndex: ind}, true)" v-model="ite.isCheck">{{ite.name}}</el-checkbox>
          </div>
          <div class="three_area">
            <div class="treeThree" v-for="(fitem, findex) in ite.children" :key="findex">
              <div class="treeThree_name pub_tree">
                <el-checkbox @change="changeEvent({item, index}, {selfItem: fitem, selfIndex: findex}, false)" v-model="fitem.isCheck">{{fitem.name}}</el-checkbox>
              </div>
            </div>
            <!-- <el-checkbox-group v-model="checkedThree" @change="handleCheckedThreeChange">
              <el-checkbox v-for="(fitem, findex) in ite.children" :label="fitem" :key="findex">{{fitem.name}}</el-checkbox>
            </el-checkbox-group> -->
          </div>
        </div>
      </div>
      <div class="btn_area">
        <el-button @click=goBack>取 消</el-button>
        <el-button class="mainBtn" @click=enterEvent>确 认</el-button>
      </div>
    </div>

    <!-- <el-tree
      :data="menuTree"
      :props="props"
      show-checkbox
      default-expand-all
      node-key="id"
      ref="userPower"
      :render-content="renderTreeEvent"
      @node-expand="changeCssOpen"
      @node-collapse="changeCssClose"
      @check-change="getAllId"
      @check="handleCheckChange">
    </el-tree> -->
    <!-- <el-tree
      :data="menuTree"
      :props="props"
      show-checkbox
      default-expand-all
      node-key="id"
      ref="userPower"
      @check-change="getAllId"
      @check="handleCheckChange">
    </el-tree> -->
    
  </div>
</template>

<script>
import { refreshAuthority } from '../../api/jurisdiction'
export default {
  name: 'authorityManagement',
  data() {
    return {
      checked: false,
      props: {
        children: 'children',
        label: 'name',
        value: 'id'
      },
      menuTree: [],
      menuIds: [],
      checkedThree: [],
      arrLength: [],
      indexData: {
        id: null,
        parentId: -1,
        weight: '1',
        name: '首页',
        path: '/index',
        icon: 'index.png',
        permission: null,
        type: '0',
      }
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(newVal, oldVal) {
        // if(newVal.query.hasOwnProperty('type')) {
          this.roleId = newVal.query.id;
          this.getMenuTreeData(newVal.query.id);
        // }
      }
    },
    checkedThree: {
      immediate: true,
      handler(newVal, oldVal) {
        // console.log(newVal)
        // console.log(this.checkedCities)
      }
    }
  },
  methods: {
    // 获取树型权限数据
    getMenuTreeData(roleId) {
      this.$request.getMenuTree({roleId}, res=>{
        if(res.code == 0) {
          this.menuTree = res.data.menuTree;
          this.menuIds = JSON.parse(JSON.stringify(res.data.menuIds));
          this.addIsCheck(this.menuTree);
          this.recursionId(this.menuTree);
          setTimeout(() => {
            this.checked = this.arrLength.length == this.menuIds.length ? true : false;
          }, 800);
          // this.setCheckedKeys(res.data.menuIds);
          // this.getTreeId(this.menuTree);
        }
      })
    },
    // 数据每级添加isCheck
    addIsCheck(treeData) {
      treeData.forEach((item, index) => {
        item.isCheck = false;
        let indexs = this.menuIds.findIndex(ite => ite == item.id)
        if(indexs != -1) {
          item.isCheck = true;
        }
        if(item.children && item.children.length != 0) {
          this.addIsCheck(item.children)
        }
      })
    },
    changeEvent(obj, selfObj, isFather) {
      let { selfItem, selfIndex } = selfObj;
      let { item, index } = obj;
      // 选中父级，子级全选或全不选
      if(isFather && selfItem.children && selfItem.children != 0) {
        selfItem.children.forEach((items, indexs)=>{
          items.isCheck = selfItem.isCheck ? true : false;
          if(items.children && items.children != 0) {
            items.children.forEach((ites, inds) => ites.isCheck = selfItem.isCheck ? true : false)
          }
        })
      }
      if(!selfItem.isCheck) {
        if(item.id != selfItem.id && isFather) {
          var arr = item.children.filter(item=>!item.isCheck);
          item.isCheck = arr.length == item.children.length ? false : true;
        } else if(item.id != selfItem.id && !isFather) {
          let fnum = 0;
          item.children.forEach((v, i)=>{
            if(v.id != selfItem.id && v.children) {
              let num = 0;
              v.children.forEach((vson, ison)=>{
                if(!vson.isCheck) num++;
                if(num == v.children.length) v.isCheck = false;
              })
              if(!v.isCheck) fnum++;
              if(fnum == item.children.length) item.isCheck = false;
            }
          })
        }
        this.checked = false;
      } else {
        if(item.id == selfItem.id) {
          let arr = this.menuTree.filter(item => item.isCheck);
          this.checked = arr.length == this.menuTree.length ? true : false;
        } else if(item.id != selfItem.id && isFather) {
          let newArr = item.children.filter(item => item.isCheck);
          item.isCheck = newArr.length != 0 ? true : false;
        } else {
          item.children.forEach((v, i)=>{
            let rnum = 0;
            if (!v.hasOwnProperty('children')) return;
            v.children.forEach((vson, ison)=>{
              if(vson.isCheck) rnum++;
              if(rnum != 0) {
                v.isCheck = true;
                item.isCheck = true;
              };
            })
          })
        }
      }
      this.$set(this.menuTree, index, item);
      if(selfItem.isCheck && !this.checked) {
        let idArr = this.recursion(this.menuTree);
        this.checked = idArr.length == this.arrLength.length ? true : false;
      }
    },
    // 全选-新
    allChange(data) {
      data.forEach(item => {
        item.isCheck = this.checked ? true : false;
        if(item.children && item.children.length != 0) {
          this.allChange(item.children);
        }
      })
    },

    renderTreeEvent(h, { node, data, store }) {
      clearInterval(timer);
      let classname = ''
      if (node.level === 3) {
        classname = 'levelname'
      }
      // 由于项目中有二级菜单也有三级菜单，就要在此做出判断。
      // if (node.level === 4 && node.childNodes.length === 0) {
      //   classname = 'levelname'
      // }
      let timer = setInterval(()=>{
        clearInterval(timer);
        this.changeCss();
      }, 2000)
      return (
        <p class={classname}>
          {node.label}
        </p>
      )
    },
    changeCss(a,b,c) {
      // console.log(a,b,c)
      var levelName = document.getElementsByClassName('levelname') // levelname是上面的最底层节点的名字
      // console.log(levelName[1].parentNode.parentNode.parentNode,666)
      // return;
      for (var i = 0; i < levelName.length; i++) {
        // cssFloat 兼容 ie6-8  styleFloat 兼容ie9及标准浏览器
        // levelName[i].parentNode.parentNode.parentNode.style.cssFloat = 'left' // 最底层的节点，包括多选框和名字都让他左浮动
        levelName[i].parentNode.parentNode.parentNode.style.display = 'flex'
        // levelName[i].parentNode.parentNode.parentNode.classList.add('parSttyle');
      }
    },
    changeCssOpen() {
      var levelName = document.getElementsByClassName('levelname')
      for (var i = 0; i < levelName.length; i++) {
        levelName[i].parentNode.parentNode.parentNode.classList.add('parSttyle')
        // levelName[i].parentNode.parentNode.parentNode.classList.add('parSttyle');
      }
    },
    changeCssClose() {
      var levelName = document.getElementsByClassName('levelname')
      for (var i = 0; i < levelName.length; i++) {
        levelName[i].parentNode.parentNode.parentNode.classList.remove('parSttyle');
      }
    },
    // 选择全选时，获取所有tree的id
    getAllId(data, isFaCheck, isSonCheck) {
      console.log(data, isFaCheck, isSonCheck)
      if(isFaCheck && this.checked) {
        this.menuIds.push(data.id);
      }
    },
    // 循环tree的数据，拿到所有id，判断是否是全选状态
    getTreeId(menuTree) {
      let arr = [];
      let newArr = this.getid(menuTree, arr);
      if(newArr.length == this.menuIds.length)
        this.checked = true
      else
        this.checked = false
      
    },
    getid(data, arr) {
      data.forEach(item=>{
        arr.push(item.id);
        if(item.children && item.children.length != 0) {
          return this.getid(item.children, arr);
        }
      })
      return arr;
    },
    handleCheckChange(val, data) {
      // console.log(data)
      let arr = data.checkedKeys;
      this.menuIds = arr.concat(data.halfCheckedKeys);
    },
    setCheckedKeys(arrIds) {
      this.$nextTick(()=>{
        arrIds.forEach(value => {
          this.$refs.userPower.setChecked(value, true, false);
        })
      })
    },
    handleCheckAllChange(val) {
      this.menuIds = [];
      if (this.checked) {
        this.$refs.userPower.setCheckedNodes(this.menuTree);
      } else {
        this.$refs.userPower.setCheckedKeys([]);
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    // 先遍历所选集的id，然后在进行权限设置确认
    enterEvent() {
      this.menuIds = this.recursion(this.menuTree);
      // console.log(this.menuIds.length)
      this.setEvent();
    },
    // 递归
    recursion(data) {
      let arr = []
      data.forEach((item, index) => {
        if(item.isCheck) arr.push(item.id)
        if(item.children && item.children.length != 0) {
          arr = arr.concat(this.recursion(item.children))
        }
      })
      return arr;
    },
    setEvent() {
      let obj = {
        roleId: this.roleId,
        menuIdSet: this.menuIds
      }
      this.$request.setupPermissionsApi(obj).then(res=>{
        if(res.data.code == 0) {
          this.Success(res.data.msg);
          refreshAuthority();
          this.goBack();
        }
      })
    },
    // 递归所有id，判断是否全选
    recursionId(data) {
      data.forEach((item, index) => {
        this.arrLength.push(item.id)
        if(item.children && item.children.length != 0) {
          this.recursionId(item.children)
        }
      })
    }
  }
}
</script>

<style lang='less'>
  .authorityManagement {
    background-color: #fff;
    // display: flex;
    // flex-direction: column;
    // align-items: center; 
    box-sizing: border-box;
    padding: 40px;
    height: calc(~'100% - 68px');
    .allSelect {
      width: 100%;
      height: 40px;
      line-height: 40px;
      background-color: #F2F2F2;
      box-sizing: border-box;
      padding-left: 8px;
    }
    .perTree {
      color: #333;
      box-sizing: border-box;
      padding: 0px 20px;
      border: 1px solid #F2F2F2;
      .pub_tree {
        margin: 12px 0px;
      }
      .treeOne {
        border-bottom: 1px dashed #e4e4e4;
        .treeOne_name {
          cursor: pointer;
          .el-checkbox__label {
            font-size: 16px;
          }
        }
        .treeTwo {
          box-sizing: border-box;
          padding-left: 30px;
          .treeTwo_name {
            cursor: pointer;
            .el-checkbox__label {
              font-size: 14px;
            }
          }
          .three_area {
            display: flex;
            padding-left: 20px;
            .treeThree {
              padding-left: 10px;
              .treeThree_name {
                cursor: pointer;
                .el-checkbox__label {
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
    }
    .el-tree {
      padding: 10px 10px;
      border: 1px solid #F2F2F2;
    }
    .btn_area {
      margin: 20px 0px 20px;
    }
    .parSttyle {
      display: flex!important;
    }
    // .el-tree-node__children {
    //   display: flex!important;
    // }
  }
</style>